import { PaginationProps } from 'antd';
import { createSlice, createAction, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, FbAdvertState, ShowFbRuleModal } from 'src/dashboard/types';
import { getFbRuleDataHttp } from './api';
import { ShopAccountIdItem } from './type';
import { setLocalShopAccountId } from 'src/utils/adHelper';

const initialState: FbAdvertState = {
  leftMenuId: new URLSearchParams(location.search).get('id') || 'rule',
  showFbRuleModal: {
    visible: false,
  },
  dataLoading: false,
  ruleDataSource: [],
  pagination: {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
  },
  shopAccountIdIOptions: [],
};

/** leftMenuId */
export const getLeftMenuId = (state: RootState) => state.fbAdvert.leftMenuId || 'rule';
/** 获取是否显示规则弹窗 */
export const getShowFbRuleModal = (state: RootState) => state.fbAdvert.showFbRuleModal;
/** 获取数据加载状态 */
export const getFbDataLoading = (state: RootState) => state.fbAdvert.dataLoading;
/** 获取规则数据 */
export const getFbRuleDataSource = (state: RootState) => state.fbAdvert.ruleDataSource || [];
/** 获取分页数据 */
export const getFbPagination = (state: RootState) => state.fbAdvert.pagination;
/** 获取selectShopAccountId */
export const getSelectShopAccountId = (state: RootState) => state.fbAdvert.selectShopAccountId;
export const getShopAccountIdIOptions = (state: RootState) => state.fbAdvert.shopAccountIdIOptions;


export const getFbRuleData = createAsyncThunk('fbAdvert/getRuleData', async (_, { getState, dispatch }) => {
  const state = getState() as RootState;
  const pagination = getFbPagination(state);
  const res = await getFbRuleDataHttp({
    page: pagination.current || 1,
    page_size: pagination.pageSize || 10
  });

  const newPagination = {
    current: res.pagination?.page || 1,
    pageSize: res.pagination?.page_size || 10,
    total: res.pagination?.element_total || 0,
    showSizeChanger: true,
  };
  if (newPagination.current > res.pagination?.page_total && res.pagination?.page_total > 0) {
    newPagination.current = res.pagination?.page_total || 1;
    dispatch(updateFbPagination(newPagination));
    dispatch(getFbRuleData());
    return;
  }
  dispatch(updateFbPagination(newPagination));
  return res.ret || [];
});


export const fbAdvertSlice = createSlice({
  name: 'fbAdvert',
  initialState,
  reducers: {
    updateLeftMenuId: (state, action: PayloadAction<string>) => {
      state.leftMenuId = action.payload;
    },
    updateShowFbRuleModal: (state, action: PayloadAction<ShowFbRuleModal | undefined>) => {
      state.showFbRuleModal = action.payload;
    },
    updateFbDataLoading: (state, action: PayloadAction<boolean>) => {
      state.dataLoading = action.payload;
    },
    updateFbPagination: (state, action: PayloadAction<PaginationProps>) => {
      state.pagination = action.payload;
    },
    updateSelectShopAccountId: (state, action: PayloadAction<string>) => {
      setLocalShopAccountId(action.payload);
      state.selectShopAccountId = action.payload;
    },
    updateShopAccountIdIOptions: (state, action: PayloadAction<ShopAccountIdItem[]>) => {
      state.shopAccountIdIOptions = action.payload;
    },
  },
  extraReducers(builder) {
    builder
    .addCase(resetModalAction, () => initialState)
    .addCase(getFbRuleData.pending, (state) => {
      state.dataLoading = true;
    })
    .addCase(getFbRuleData.fulfilled, (state, action) => {
      state.dataLoading = false;
      if (!action.payload) {
        return;
      }
      state.ruleDataSource = action.payload;
    })
    .addCase(getFbRuleData.rejected, (state) => {
      state.dataLoading = false;
    })
  }
})

const resetModalAction = createAction('fbAdvert/reset-action');

export const {
  updateLeftMenuId,
  updateShowFbRuleModal,
  updateFbDataLoading,
  updateFbPagination,
  updateSelectShopAccountId,
  updateShopAccountIdIOptions,
} = fbAdvertSlice.actions;

export { resetModalAction };

export default fbAdvertSlice.reducer;
