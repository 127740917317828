const DEFAULT_LENGTH = 32;

/**
 * 随机生成len长度的字母数字字符串
 * @param len 长度
 * @returns 
 */
const generateRandomString = (len = DEFAULT_LENGTH) => {
  const characters = 'ABCDEF0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export default generateRandomString;
