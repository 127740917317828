import { allHttpRequest } from "src/utils/httpRequest";
import { ConditionConfig } from "../Adtopic/component/RightContent/AutoRule/RuleDetail/Condition/type";
import { CampaignRequest, CreateRuleItem, PaginationRequest, RuleItem, RuleLogRequest } from "./type";

/** 获取条件相关配置 */
export const getFbConditionConfig = async (): Promise<ConditionConfig> => {
  const res = await allHttpRequest.post('/llm_server/fb/config/condition');
  return res.json as ConditionConfig;
}

/** 创建规则 */
export const createFbRuleHttp = async (ruleContent: CreateRuleItem) => {
  const res = await allHttpRequest.post('/llm_server/fb/ad/rule/create', ruleContent);
  return res.json;
}

/** 获取规则列表 */
export const getFbRuleDataHttp = async (data: PaginationRequest) => {
  const res = await allHttpRequest.post('/llm_server/fb/list/rule', data);
  return res.json;
}

/** 删除规则 */
export const deleteFbRuleHttp = async (id: number) => {
  const res = await allHttpRequest.post(`/llm_server/fb/ad/rule/delete/${id}`);
  return res.json;
};

/** 获取单条规则信息 by id */
export const getRuleDataById = async (id: number) => {
  const res = await allHttpRequest.get(`/llm_server/fb/list/rule/${id}`);
  return res.json;
};

/** 更新规则 */
export const updateFbRuleHttp = async (ruleContent: RuleItem) => {
  const res = await allHttpRequest.post('/llm_server/fb/ad/rule/update', ruleContent);
  return res.json;
}

/** 获取广告活动列表 */
export const getFbCampaignList = async (data: CampaignRequest) => {
  const res = await allHttpRequest.post('/llm_server/fb/list/campaign', data);
  return res.json;
};

/** 获取日志列表 */
export const getFbLogList = async (data: RuleLogRequest) => {
  const res = await allHttpRequest.post('/llm_server/fb/list/rule_history', data);
  return res.json;
};

/** 获取账户广告成效数据 */
export const getFbAccountInsights = async () => {
  const res = await allHttpRequest.post('/llm_server/fb/insights/account');
  return res.json;
}

/** 获取账户ShopAccountId信息 */
export const getShopAccountIdData = async () => {
  const res = await allHttpRequest.get('/llm_server/fb/ad/rule/fb_shop_account');
  return res.json;
}
