import { SupersetClient } from "@superset-ui/core";
import { COUNTRY_NAME_KEY, SHOP_ACCOUNT_NAME_KEY, getLocalShopAccountId, getLocalShopAccountName } from "./adHelper";
import generateRandomString from "./generateRandomString";

export const GET_SHOP_ACCOUNT_NAME_URL = '/llm_server/user/shop_account_name';

const getCommonHeaders = (url?: string) => {
  const localShopAccountName = getLocalShopAccountName();
  const headers: Record<string, string> = {};

  if (url?.includes('llm_server')) {
    // llm_server 相关接口headers加上 trace-id 随机字符串
    headers['trace-id'] = generateRandomString();
  }

  if (url?.includes('llm_server/fb')) {
    headers['fb-shop-account-id'] = getLocalShopAccountId() || '';
  }

  if (!localShopAccountName) {
    return headers;
  }

  if (url === GET_SHOP_ACCOUNT_NAME_URL) {
    return headers;
  }

  const last_index = localShopAccountName.lastIndexOf('_');
  if (last_index === -1) {
    return headers;
  }

  const localCountryName = localShopAccountName.substring(last_index + 1);
  const localShopName = localShopAccountName.substring(0, last_index);
  if (!localCountryName || !localShopName) {
    return headers;
  }

  headers[SHOP_ACCOUNT_NAME_KEY] = encodeURIComponent(localShopName);
  headers[COUNTRY_NAME_KEY] = localCountryName;

  return headers;
};

class HttpRequest {
  protected host: string = `${window.location.host}/extra_server/`
  async get(url: string) {
    const result = await SupersetClient.get({
      host: this.host,
      endpoint: url,
      headers: getCommonHeaders(url),
    });
    return result;
  }
  
  async post(url: string, body?: any) {
    const result = await SupersetClient.post({
      host: this.host,
      endpoint: url,
      mode: 'cors',
      // credentials: 'include',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json;charset=UTF-8',
        ...getCommonHeaders(url),
      },
      body: body ? JSON.stringify(body) : undefined,
      parseMethod: 'json',
    });
    return result;
  }

  async delete(url: string) {
    const result = SupersetClient.delete({
      host: this.host,
      endpoint: url,
      mode: 'cors',
      // credentials: 'include',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/x-www-form-urlencoded',
        ...getCommonHeaders(url),
      },
      parseMethod: 'json',
    })
    return result;
  }

  async put(url: string, body?: any) {
    const result = await SupersetClient.put({
      host: this.host,
      endpoint: url,
      mode: 'cors',
      // credentials: 'include',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json;charset=UTF-8',
        ...getCommonHeaders(url),
      },
      body: body ? JSON.stringify(body) : undefined,
      parseMethod: 'json',
    });
    return result;
  }

  async pput(url: string) {
    const result = await SupersetClient.put({
      host: this.host,
      endpoint: url,
      mode: 'cors',
      // credentials: 'include',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json;charset=UTF-8',
        ...getCommonHeaders(url),
      },
    });
    return result;
  }
}

class ApiHttpRequest extends HttpRequest {
  protected host: string = `${window.location.host}/api/`;
}

const apiHttpRequest = new ApiHttpRequest();

class AllHttpRequest extends HttpRequest {
  protected host: string = `${window.location.host}/`;
}

const allHttpRequest = new AllHttpRequest();

const httpRequest = new HttpRequest();

export {
  apiHttpRequest,
  allHttpRequest,
};

export default httpRequest;
